:root {
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-32: 8rem;
  --spacing-40: 10rem;
  --spacing-48: 12rem;
  --spacing-56: 14rem;
  --spacing-64: 16rem;

  --spacing-nav: 4.5rem;
  --spacing-nav-mobile: 4.5rem;

  --z-base: 0;
  --z-layer-1: 100;
  --z-layer-2: 200;
  --z-layer-3: 300;
  --z-layer-4: 400;
  --z-layer-5: 500;
  --z-layer-6: 600;
  --z-layer-7: 700;
  --z-layer-8: 800;
  --z-layer-9: 900;

  --font-sans: "Sohne", system-ui, -apple-system, sans-serif;
  --font-accent: "Sohne Breit", system-ui, sans-serif;
  --font-serif: "Reckless";

  --text-super: max(2.5rem, 5vw);
  --text-display: 2.5rem;
  --text-heading: 1.25rem;
  --text-large: 1rem;
  --text-copy: 0.875rem;
  --text-fine: 0.625rem;

  --width-text: 30em;

  --line-height-heading: 1.2;
  --line-height-copy: 1.5;
  --line-height-solid: 1;

  --transparent: rgba(0, 0, 0, 0);
  --color-black: hsla(40, 2%, 1%, 1);
  --color-darker-gray: hsla(40, 2%, 20%, 1);
  --color-dark-gray: hsla(40, 2%, 40%, 1);
  --color-gray: hsla(40, 2%, 50%, 1);
  --color-light-gray: hsla(40, 14%, 80%, 1);
  --color-lighter-gray: hsla(40, 11%, 84%, 1);
  --color-alabaster: hsl(53, 50%, 91%);
  --color-off-white: hsla(40, 14%, 92%, 1);
  --color-white: hsla(0, 0%, 100%, 1);
  --color-green: hsl(159, 100%, 20%);
  --color-yellow: hsl(50, 100%, 50%);
  --color-bfcm-yellow: #fcfbef;
  --color-bfcm-green: #14a166;
  --color-red: hsl(0, 45%, 45%);
  --color-orange: hsla(23, 100%, 37%, 1);
  --color-lilac: hsl(327, 12%, 69%);

  /* ALPHAS */
  /* These are included in some design files */
  /* Should probably be replaced at some point */
  --color-black-alpha-10: hsla(60, 20%, 1%, 10%);
  --color-black-alpha-20: hsla(60, 20%, 1%, 20%);
  --color-black-alpha-30: hsla(60, 20%, 1%, 30%);
  --color-black-alpha-40: hsla(60, 20%, 1%, 40%);
  --color-black-alpha-80: hsla(60, 20%, 1%, 80%);

  --palette-text: var(--color-darker-gray);
  --palette-text-alt: var(--color-black);
  --palette-text-dark: var(--color-white);
  --palette-text-dark-alt: var(--color-off-white);

  --palette-background: var(--color-white);
  --palette-background-alt: var(--color-off-white);
  --palette-background-dark: var(--color-darker-gray);
  --palette-background-dark-alt: var(--color-black);

  --palette-border: var(--color-black);
  --palette-border-dark: var(--color-white);

  --swiper-navigation-color: var(--palette-border);
}
